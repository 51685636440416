import React from 'react'
import { Link } from 'gatsby'
import { MainNavbar, SubNavbar } from '../components/heading'
import Footer from '../components/heading/footer'
import Singularity from '../components/singularity'
import SEO from '../components/seo'

const NotFoundPage = ({ data, location }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <SEO title={`404 Not Found`} />
      <MainNavbar location={location} />
      <SubNavbar location={location} />
      <div
        className="flex flex-col w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg min-h-full justify-center items-center shadow-md pt-10 md:pt-24 pb-24 px-4 md:px-10 mb-10 md:mb-0"
        style={{
          color: 'var(--textNormal)',
          backgroundColor: 'var(--bg-card)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
          fontSize: '1rem',
          lineHeight: 1.6
        }}
      >
        <svg
          className={`fill-current py-10 h-40-v md:h-45-v lg:h-45-v`}
          data-name="Found not"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 840 726.85"
        >
          <defs>
            <linearGradient
              id="a"
              x1="432"
              y1="245.78"
              x2="432"
              y2="18.85"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="gray" stopOpacity=".25" />
              <stop offset=".54" stopColor="gray" stopOpacity=".12" />
              <stop offset="1" stopColor="gray" stopOpacity=".1" />
            </linearGradient>
            <linearGradient
              id="b"
              x1="447.27"
              y1="1916.01"
              x2="447.27"
              y2="1730.58"
              gradientTransform="rotate(18.2 4156.739 1702.728)"
            />
            <linearGradient
              id="c"
              x1="334.27"
              y1="1539.38"
              x2="334.27"
              y2="1353.95"
              gradientTransform="rotate(18.2 4156.739 1702.728)"
            />
            <linearGradient id="d" x1="432" y1="258.09" x2="432" y2="103.27" />
          </defs>
          <ellipse
            cx="432"
            cy="132.31"
            rx="146.46"
            ry="113.46"
            fill="url(#a)"
            opacity=".8"
          />
          <ellipse
            cx="432"
            cy="132.31"
            rx="143.21"
            ry="110.94"
            fill="#fc8181"
          />
          <path
            d="M311.94 144.64c0-61.27 64.11-110.94 143.2-110.94a175.54 175.54 0 0171.36 14.73c-25.17-17-58.1-27.33-94.14-27.33-79.09 0-143.21 49.67-143.21 110.94 0 41.14 28.91 77.05 71.86 96.21-30.07-20.34-49.07-50.25-49.07-83.61z"
            fill="#fff"
            opacity=".1"
          />
          <path
            fill="#fc8181"
            opacity=".2"
            d="M432 176.9l158.76 274.98 158.76 274.97H114.48l158.76-274.97L432 176.9z"
          />
          <path
            d="M649.67 652.79L662 616.2a62.66 62.66 0 00-23.2-70.89l.1-.06a61.48 61.48 0 00-15.79-8l-.17-.06-.19-.06a61.55 61.55 0 00-16.9-3.18 59.28 59.28 0 00-6.11 0 62.32 62.32 0 00-10.49 1.45c-1 .22-2 .47-2.91.74s-1.93.56-2.88.87c-1.9.63-3.77 1.34-5.6 2.15-.92.4-1.83.82-2.72 1.27a61.47 61.47 0 00-7.75 4.56c-.83.57-1.64 1.16-2.44 1.76a62 62 0 00-21.24 29.6L524.33 634a61.86 61.86 0 00-2.68 28.12c.2 1.45.45 2.89.74 4.32s.65 2.84 1 4.24c.27.93.55 1.86.86 2.78.46 1.37 1 2.74 1.51 4.08.37.89.76 1.78 1.17 2.66.81 1.75 1.71 3.46 2.68 5.13s2 3.3 3.16 4.88c.84 1.18 1.73 2.34 2.65 3.46 1.24 1.5 2.54 3 3.92 4.33a61.81 61.81 0 0021 13.89l.34.14a21.66 21.66 0 011.4-2.17c3-4.11 8.22-7 13.19-5.92.36.08.72.19 1.08.3 2.52.89 5 2.48 7.43 3.26a6.66 6.66 0 005.61-.07c4.86-2.59 3.06-10.76 7.23-14.34a6.5 6.5 0 01.61-.46 1.88 1.88 0 01-.16-.23c3.22-2 7.68-.67 11.39.62 4 1.41 9.22 2.36 12-.91 1.88-2.24 1.74-5.65 3.53-8 2.08-2.7 6-3 9.39-2.52l.17.05c6.5 2.68 13.6-1.77 13.1-8.78-.59-9.35 2.35-18.16 3.02-20.07z"
            transform="translate(-180 -86.57)"
            fill="url(#b)"
            opacity=".3"
          />
          <path
            d="M383.21 620.21c2.92-4 7.93-6.8 12.73-5.71s9.35 5.66 13.62 3.37c5.84-3.12 1.7-14.59 11.18-15.44a12.44 12.44 0 015.77 1c4 1.63 10.26 3.47 13.34-.2 1.81-2.16 1.68-5.45 3.41-7.69 2-2.6 5.81-2.93 9.06-2.43l.16.05c6.27 2.59 13.13-1.71 12.64-8.47-.63-9 2.23-17.48 2.85-19.32l11.88-35.31A60.38 60.38 0 00442 453.77a60.38 60.38 0 00-76.27 37.86L347 547.16a60.39 60.39 0 0034.82 75.15 21.47 21.47 0 011.39-2.1z"
            fill="#fff"
          />
          <circle cx="405.35" cy="486.08" r="6.51" opacity=".2" />
          <circle cx="442.37" cy="498.53" r="6.51" opacity=".2" />
          <ellipse
            cx="595.55"
            cy="603.56"
            rx="9.76"
            ry="6.51"
            transform="rotate(-71.4 445.368 685.53)"
            fill="#e0e0e0"
          />
          <circle cx="552.53" cy="487.52" r="9.76" fill="#fff" />
          <circle cx="583.45" cy="472.17" r="4.88" fill="#fff" />
          <g opacity=".5">
            <path
              d="M662.67 259.79L675 223.2a62.66 62.66 0 00-23.2-70.89l.1-.06a61.48 61.48 0 00-15.79-8l-.17-.06-.19-.06a61.55 61.55 0 00-16.9-3.18 59.28 59.28 0 00-6.11 0 62.32 62.32 0 00-10.49 1.45c-1 .22-2 .47-2.91.74s-1.93.56-2.88.87c-1.9.63-3.77 1.34-5.6 2.15-.92.4-1.83.82-2.72 1.27a61.47 61.47 0 00-7.75 4.56c-.83.57-1.64 1.16-2.44 1.76a62 62 0 00-21.24 29.6L537.33 241a61.86 61.86 0 00-2.68 28.12c.2 1.45.45 2.89.74 4.32s.65 2.84 1 4.24c.27.93.55 1.86.86 2.78.46 1.37 1 2.74 1.51 4.08.37.89.76 1.78 1.17 2.66.81 1.75 1.71 3.46 2.68 5.13s2 3.3 3.16 4.88a61.73 61.73 0 006.57 7.79 61.81 61.81 0 0021 13.89l.34.14a21.66 21.66 0 011.4-2.17c3-4.11 8.22-7 13.19-5.92.36.08.72.19 1.08.3 2.52.89 5 2.48 7.43 3.26a6.66 6.66 0 005.61-.07c4.86-2.59 3.06-10.76 7.23-14.34a6.5 6.5 0 01.61-.46 1.88 1.88 0 01-.16-.23c3.22-2 7.68-.67 11.39.62 4 1.41 9.22 2.36 12-.91 1.88-2.24 1.74-5.65 3.53-8 2.08-2.7 6-3 9.39-2.52h.17c6.5 2.68 13.6-1.77 13.1-8.78-.59-9.3 2.35-18.11 3.02-20.02z"
              transform="translate(-180 -86.57)"
              fill="url(#c)"
              opacity=".3"
            />
            <path
              d="M396.21 227.21c2.92-4 7.93-6.8 12.73-5.71s9.35 5.66 13.62 3.37c5.84-3.12 1.7-14.59 11.18-15.44a12.44 12.44 0 015.77 1c4 1.63 10.26 3.47 13.34-.2 1.81-2.16 1.68-5.45 3.41-7.69 2-2.6 5.81-2.93 9.06-2.43l.16.05c6.27 2.59 13.13-1.71 12.64-8.47-.63-9 2.23-17.48 2.85-19.32l11.88-35.31A60.38 60.38 0 00455 60.77a60.38 60.38 0 00-76.27 37.86L360 154.16a60.39 60.39 0 0034.82 75.15 21.47 21.47 0 011.39-2.1z"
              fill="#fff"
            />
            <circle cx="418.35" cy="93.08" r="6.51" opacity=".2" />
            <circle cx="455.37" cy="105.53" r="6.51" opacity=".2" />
          </g>
          <path
            d="M569.52 99.43a87.52 87.52 0 012.79 22c0 61.27-64.11 110.94-143.2 110.94-64.73-.04-119.42-33.3-137.11-78.94 13.14 50.77 71 89 140.41 89 79.09 0 143.21-49.67 143.21-110.94a88.16 88.16 0 00-6.1-32.06z"
            opacity=".1"
          />
          <ellipse
            cx="432"
            cy="180.68"
            rx="296.18"
            ry="77.41"
            fill="url(#d)"
            opacity=".8"
          />
          <path
            d="M432 105.83c-158.18 0-286.42 33.51-286.42 74.86h572.84c0-41.35-128.24-74.86-286.42-74.86zM432 255.54c158.18 0 286.42-33.51 286.42-74.85H145.58c0 41.34 128.24 74.85 286.42 74.85z"
            fill="#fff"
          />
          <path
            d="M432 255.54c158.18 0 286.42-33.51 286.42-74.85H145.58c0 41.34 128.24 74.85 286.42 74.85z"
            opacity=".05"
          />
          <rect
            x="295.3"
            y="161.15"
            width="273.4"
            height="39.06"
            rx="17.22"
            opacity=".05"
          />
          <path
            d="M133.89 57.81a3.68 3.68 0 01-2.05-4.44 1.86 1.86 0 00.08-.41 1.84 1.84 0 00-3.31-1.22 1.82 1.82 0 00-.2.36 3.67 3.67 0 01-4.44 2.05 2 2 0 00-.41-.08 1.84 1.84 0 00-1.22 3.31 1.62 1.62 0 00.36.21 3.68 3.68 0 012.05 4.44 1.89 1.89 0 00-.08.4 1.84 1.84 0 003.31 1.23 1.65 1.65 0 00.2-.37 3.67 3.67 0 014.44-2 2 2 0 00.41.08 1.84 1.84 0 001.22-3.31 1.62 1.62 0 00-.36-.25zM642.89 363.81a3.68 3.68 0 01-2-4.44 1.86 1.86 0 00.08-.41 1.84 1.84 0 00-3.31-1.22 1.82 1.82 0 00-.2.36 3.67 3.67 0 01-4.44 2.05 2 2 0 00-.41-.08 1.84 1.84 0 00-1.22 3.31 1.62 1.62 0 00.36.21 3.68 3.68 0 012 4.44 1.89 1.89 0 00-.08.4 1.84 1.84 0 003.31 1.23 1.65 1.65 0 00.2-.37 3.67 3.67 0 014.44-2 2 2 0 00.41.08 1.84 1.84 0 001.22-3.31 1.62 1.62 0 00-.36-.25zM80.89 505.81a3.68 3.68 0 01-2.05-4.44 1.86 1.86 0 00.08-.41 1.84 1.84 0 00-3.31-1.22 1.82 1.82 0 00-.2.36 3.67 3.67 0 01-4.44 2.05 2 2 0 00-.41-.08 1.84 1.84 0 00-1.22 3.31 1.62 1.62 0 00.36.21 3.68 3.68 0 012.05 4.44 1.89 1.89 0 00-.08.4 1.84 1.84 0 003.31 1.23 1.65 1.65 0 00.2-.37 3.67 3.67 0 014.44-2 2 2 0 00.41.08 1.84 1.84 0 001.22-3.31 1.62 1.62 0 00-.36-.25zM25.89 174.81a3.68 3.68 0 01-2-4.44 1.86 1.86 0 00.08-.41 1.84 1.84 0 00-3.31-1.22 1.82 1.82 0 00-.2.36 3.67 3.67 0 01-4.44 2.05 2 2 0 00-.41-.08 1.84 1.84 0 00-1.22 3.31 1.62 1.62 0 00.36.21 3.68 3.68 0 012.05 4.44 1.89 1.89 0 00-.08.4 1.84 1.84 0 003.31 1.23 1.65 1.65 0 00.2-.37 3.67 3.67 0 014.44-2 2 2 0 00.41.08 1.84 1.84 0 001.22-3.31 1.62 1.62 0 00-.41-.25zM632.89 6.81a3.68 3.68 0 01-2-4.44 1.86 1.86 0 00.08-.41 1.84 1.84 0 00-3.31-1.22 1.82 1.82 0 00-.2.36 3.67 3.67 0 01-4.44 2 2 2 0 00-.41-.08 1.84 1.84 0 00-1.27 3.41 1.62 1.62 0 00.36.21 3.68 3.68 0 012 4.44 1.89 1.89 0 00-.08.4 1.84 1.84 0 003.38 1.18 1.65 1.65 0 00.2-.37 3.67 3.67 0 014.44-2 2 2 0 00.41.08 1.84 1.84 0 001.22-3.31 1.62 1.62 0 00-.38-.25z"
            fill="#4d8af0"
            opacity=".5"
          />
          <g opacity=".5" fill="#47e6b1">
            <path d="M110 257.85h3v17h-3z" />
            <path d="M120 264.85v3h-17v-3z" />
          </g>
          <g opacity=".5" fill="#47e6b1">
            <path d="M830 177.85h3v17h-3z" />
            <path d="M840 184.85v3h-17v-3z" />
          </g>
          <g opacity=".5" fill="#47e6b1">
            <path d="M730 456.85h3v17h-3z" />
            <path d="M740 463.85v3h-17v-3z" />
          </g>
          <circle cx="723" cy="81.85" r="6" fill="#f55f44" opacity=".5" />
          <circle cx="6" cy="12.85" r="6" fill="#f55f44" opacity=".5" />
          <circle cx="186" cy="357.85" r="6" fill="#f55f44" opacity=".5" />
          <ellipse cx="234" cy="154.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="234" cy="207.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="621" cy="154.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="621" cy="207.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="432" cy="124.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="457" cy="51.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="392" cy="81.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="517" cy="94.43" rx="34" ry="7" opacity=".05" />
          <ellipse cx="432" cy="228.43" rx="34" ry="7" opacity=".05" />
        </svg>
        <div className="text-5xl mt-2 mb-10">404</div>
        <div className="text-xl text-center mb-4">
          There has been enormous efforts in SETI, unfortunately one has been
          found yet not!
        </div>
        <div className="text-xl">So is the page you are looking for...</div>
        <Link
          className="shadow-none text-xl underline mt-10 text-red-400"
          to="/"
        >
          ដើមទំព័រ
        </Link>
      </div>
      <div className="mb-2">
        <Singularity location={location} />
      </div>
      <Footer />
    </div>
  )
}

export default NotFoundPage
